<template>
  <div>
    <!-- <van-sticky type="primary">
      <head-bar :title="'日程详情'" @onClickLeft="onClickLeft" />
    </van-sticky> -->
    <div class="q-result">
      <div class="content">
        <div class="title">您的答卷已经提交，感谢您的参与！</div>
        <van-button type="info" size="small" @click="backHome" class="back-home"
          >返回首页</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import headBar from "@/components/headBar/headBar.vue";
export default {
  name: "QuestionResult",
  components: {
    // headBar,
  },
  methods: {
    backHome() {
      // this.$router.push('/index/' +  this.$store.state.miceInfo.miceLink)
      this.$router.push("/index/0f9991f1f7f446ffbd5c12eaa4636122");
    },
    onClickLeft() {
      this.$router.back(-1)
    }
  },
};
</script>

<style lang="less" scoped>
.q-result {
  width: 100%;
  height: 100vh;
  background-color: #f7f8f6;
  display: flex;
  box-sizing: border-box;
  .content {
    width: 92%;
    padding: 15px;
    box-sizing: border-box;
    margin: 30px auto;
    background-color: #ffffff;
    font-size: 16px;
    text-align: center;
    color: #353535;
    border-radius: 4px;
    .back-home {
      margin-top: 30px;
      font-size: 15px;
    }
  }
}
</style>
